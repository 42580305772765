// import { ForumUI as ForumUI_Base } from 'firebase-forum';
import {BaseComponent} from "react-vextensions";

export class ForumUI extends BaseComponent<{}, {}> {
	render() {
		/* return (
			<ForumUI_Base/>
		); */
		return null;
	}
}